import React from 'react'
import styled from 'styled-components'
import Button from './Button';
import Img from 'gatsby-image'
import { Link } from 'gatsby'

const Col = styled.div`
  h3 {
    font-family: Raleway;
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 48px;
    /* identical to box height, or 126% */

    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: ${props => props.theme.colors.back};
  }

  p {
    font-family: Raleway;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.0727273px;
    margin: 2em 0;
  }

  &.col-medium {
    width: ${props => (props.sameCol ? "50%" : "60%")};
    padding: ${props => (props.sameCol ? "0 1em 0 1em" : props.inverted ? "0 3em 0 1em" : "0 1em")};
  }
  &.col-small {
    width: ${props => (props.sameCol ? "50%" : "40%")};
    padding: ${props => (props.sameCol ? "0 3em 0 1em" : props.inverted ? "0 1em 0 3em" : "0 1em")};
  }

  @media screen and (max-width: 1100px) {
    h3 {
      font-size: 30px;
      line-height: 40px;
      letter-spacing: 0.0727273px;
    }
    p {
      font-size: 20px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 800px) {
    &.col-medium {
      width: 100%;
      padding: 0 0.5em;
    }
    &.col-small {
      margin: 1em 0 1.5em 0;
      padding: 0 0.5em;
      width: 100%;
    }

    h3 {
      font-size: 30px;
      line-height: 32px;
      letter-spacing: 0.0727273px;
    }
    
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
`

const Row = styled.section`
  display: flex;
  width: 100%;
  max-width: ${props => (props.maxWidth ? props.maxWidth : props.theme.sizes.maxWidthCentered)};
  align-items: center;
  margin: 1em 0 1em 0;

  & .button-content {
    width: auto !important;
    display: flex;
    flex-wrap: wrap;

    a {
      text-decoration: none;
      margin: 5px 0px;
    }
  }

  & .feature-separator {
    width: 54%;
    margin: 28px 0 23px;
    border-bottom: 1px solid ${props => props.theme.colors.tertiaryTwo};
  }

  &.row-right {
    flex-direction: row-reverse;
    margin: 1em 0 3em 0;

    & .button-content {
      & a:not(:first-child) {
        margin: 5px 10px;
      }
    }
  }
  
  &.row-left {
    text-align: right;

    & .button-content {
      justify-content: flex-end;
      & a:not(:last-child) {
        margin: 5px 10px;
      }
    }

    & .feature-separator {
      margin-left: auto;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;

    &.row-right, &.row-left {
      flex-direction: column;
      text-align: center;
      margin: 1em 0 1em 0;

      & .button-content {
        justify-content: center;
        & a {
          margin: 5px 10px;
        }
      }
    }

    & .feature-separator {
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (min-width: 600px) {
    padding: 0 3em 0 0;
  }

  @media screen and (min-width: 700px) {
    padding: 0;
  }
`

const BgImg = styled(Img)`
  height: auto;
  width: 100%;
`

const BgImg2 = styled.img`
  height: auto;
  width: 100%;
`

const FeatureItem = props => {
  console.log(props.buttons)
  return (
    <Row className={`row-${props.contentPosition}`} maxWidth={props.maxWidth}>
      <Col className={props.invertedSizes ? "col-medium" : "col-small"} inverted={props.invertedSizes} sameCol={props.sameCols}>
        <BgImg2 src={props.image}/>
      </Col>
      
      <Col 
        className={props.invertedSizes ? "col-small" : "col-medium"} 
        inverted={props.invertedSizes} 
        sameCol={props.sameCols} 
        tertiary={props.tertiary}
      >
        <h3>{props.title || ''}</h3>
        <div className="feature-separator"/>
        <p 
          dangerouslySetInnerHTML={{
          __html: props.description || ''
          }}
        />

        {props.buttons &&
          <div className="button-content">
            {props.buttons.map((button, index) =>(
              <Link key={`button-feature-${index}`} to={button.to || '/signup/bform'}>
                <Button 
                  padding="10px 10px" 
                  borderRadius="6.28832px"
                  minWidth="170px"
                  className={`${button.rounded && 'rounded'} tertiary`}>
                  {button.text}
                </Button>
              </Link>
            ))}
          </div>
        }
      </Col>
    </Row>
  )
}

export default FeatureItem

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  margin-top: 3em;
  margin-bottom: 4em;

  .testimonials-content {
    margin-top: 4.8em;
    display: flex;
    flex-direction: row;
  }

  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    .testimonials-content {
      flex-direction: column;
      margin-top: 2em;
    }
    padding: 0 1em;
  }
  @media screen and (max-width: ${props => props.theme.responsive.small}) {
    .testimonials-content {
      flex-direction: column;
      margin-top: 2em;
    }
    padding: 0 0;
  }
`

const Title = styled.h1`
  width: 100%;
  padding-top: 1.3em;
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 125%;
  font-size: 24px;
  text-align: center;
  letter-spacing: 0.177083px;
  color: ${props => props.theme.colors.tertiary};
`

const TestimonialsSection = props => {

  return (
    <Wrapper>
      <Title>{props.testimonialsTitle}</Title>
      <div className='testimonials-content'>{props.children}</div>
    </Wrapper>
  )
}

export default TestimonialsSection

import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import styled from 'styled-components'

import Container from '../components/Container'
import SEO from '../components/SEO'
import Title from '../components/Title'
import WeWorkWithSection from '../components/WeWorkWithSection'
import HomeFeatures from '../components/HomeFeatures'
import GetQuoteSection from '../components/GetQuoteSection'
import TestimonialSection from '../components/testimonialsSection'
import TestimonialCard from '../components/testimonialCard'
import HomeHero from '../components/HomeHero'

const PartnershipSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em 0;

  & .col-logo {
    flex-basis: 230px;
    padding: 1em;
    max-width: 30%;
  }
`;

const Index = ({ data, pageContext }) => {
  const testimonials =
    data.allContentfulTestimonial.edges.length > 3
      ? data.allContentfulTestimonial.edges.slice(0, 3)
      : data.allContentfulTestimonial.edges
  const feacturesGallery =
    data.contentfulHomePage.feacturesGallery.length > 1
      ? data.contentfulHomePage.feacturesGallery
      : []
  const contentHome = data.contentfulHomePage

  return (
    <Layout whiteNav>
      <SEO />
      <HomeHero
        image={contentHome.heroImage}
        title={contentHome.heroImageSubtitle}
        contentPosition={'left'}
        heroBackground={'rgba(0, 0, 0, 0.65)'}
        contentWidth="70"
        titleStyle={{color: 'white'}}
        buttons={[
          { buttonText: 'INFLUENCER MARKETING', buttonAction: 'brands' },
          { buttonText: 'DIGITAL SERVICES', buttonAction: 'https://join.embello.com/digital-services/' }
        ]}
      />
      <Title paddingTop="4em" paddingBottom="1.5em" title={contentHome.heroImageTitle} />
      <WeWorkWithSection/>
      <Container padding={'5em 1.5em 2em'}>
        <GetQuoteSection 
          consultationTitle={'A holistic approach to building authentic brand presence'}
          titleLg
          linkTo="https://calendly.com/embello"
          externalLink
          linkTitle='SCHEDULE CALL'
          noMargin
          contentWidth="796px"
          background="rgba(236, 221, 220, 0.25)"
          paddingButton="10.5px 25px"
        />

        <HomeFeatures
          margin="2em 0"
          feactures={feacturesGallery}
        />

        <GetQuoteSection 
          consultationTitle={'PARTNERSHIPS'}
          titleMd
          linkTo="/partnerships"
          linkTitle='LEARN MORE'
          noMargin
          contentWidth="796px"
          background="rgba(236, 221, 220, 0.25)"
          padding="3em 1.5em 2.6em"
          paddingButton="10.5px 25px"
          contentMargin="0"
        >
          <PartnershipSection>
            <div className="col-logo">
              <img src="/images/Martha-Stewart-Living-Logo.png" alt="Martha Stewart Living logo"/>
            </div>
            <div className="col-logo">
              <img src="/images/Design-Milk-Logo.png" alt="Design Milk logo"/>
            </div>
            <div className="col-logo">
              <img src="/images/IDK-Logo.png" alt="IDK logo"/>
            </div>
          </PartnershipSection>
        </GetQuoteSection>

        <TestimonialSection 
          testimonialsTitle={contentHome.testimonialsTitle}>
          {testimonials.map(({ node: testimonial }, index) => (
            <TestimonialCard key={testimonial.id} {...testimonial} index={index} brandLogoHeight={testimonial.brandLogo.description} />
          ))}
        </TestimonialSection>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulInfluencer(sort: { order: ASC, fields: [order] }) {
      edges {
        node {
          name
          instagramHandler
          footerTextColor
          headline
          id
          location
          slug
          heroImage {
            title
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    allContentfulTestimonial(sort: { order: ASC, fields: [order] }) {
      edges {
        node {
          name
          id
          slug
          heroImage {
            title
            fluid(maxWidth: 51) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          brandLogo {
            title
            description
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          body {
            childMarkdownRemark {
              html
              excerpt(pruneLength: 80)
            }
          }
        }
      }
    }
    allContentfulCaseStudy(sort: { order: ASC, fields: [createdAt] }, limit: 3) {
      edges {
        node {
          title
          slug
          id
          heroPreview {
            title
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          brandLogo {
            title
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          engagements
          impressions
        }
      }
    }
    contentfulHomePage(version: { eq: "current" }) {
      heroImage {
        title
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      slides {
        title
        image {
          title
          fluid(maxWidth: 1920) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        slideContent {
          childMarkdownRemark {
            html
          }
        }
        contentPosition
        widthContentPct
        buttonText
        buttonAction
      }
      description
      stepsTitle
      steps {
        list {
          title
          description
        }
      }
      completeQuestionnaireTitle
      completeQuestionnaireSubtitle {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      heroImageTitle
      heroImageSubtitle
      heroImageFooter {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      subtitle
      influencersTitle
      testimonialsTitle
      consultationTitle
      consultationSubtitle {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
      feacturesGallery {
        id
        title
        description
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Index

import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 1440px; 
  margin: 0 auto;
  ${props => props.padding || '0 2em 0 0'};

  /* New */

  .row-we-work {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  .col-we-work-2 {
    flex-basis: 180px;
    padding: 1em 2.5em;
    text-align: -webkit-center;
    max-width: 14%;
  }

  @media screen and (max-width: 1080px) {
    .col-we-work-2 {
      padding: 1em 2em;
      max-width: 14.2%;
    }
  }

  @media screen and (max-width: 935px) {
    .col-we-work-2 {
      padding: 1em 1.5em;
      max-width: 17%;
    }
  }

  @media screen and (max-width: 720px) {
    .col-we-work-2 {
      padding: 1em 1em;
      max-width: initial;
      flex-basis: 120px;
    }
    .row-we-work {
      justify-content: space-around;
    }
  }

  /* Old */
  
  .col-we-work {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .col-we-work .we-work-logo {
    flex-basis: 220px;
    padding: 0 2em;
    text-align: -webkit-center;
  }
  
  .col-we-work .we-work-logo img, 
  .col-we-work .we-work-logo img {
    max-width:100%;
    height: auto;
    width: auto;
  }
  
  @media screen and (max-width: 790px) {
    /* flex-direction: column;
    padding: 0 0.5em 0 0;
    
    .col-we-work {
      width: 100%;
      
      &.extra-margin {
        margin-bottom: 2em;
      }
    }

    .col-we-work:last-child {
      flex-direction: row-reverse;
    }

    .col-we-work .we-work-logo {
      text-align: -webkit-left;
    }

    .col-we-work:last-child .we-work-logo.image-small {
      text-align: -webkit-right;
    } */
    
  }

`

const WeWorkWithSection = ({ padding }) => {
  return (
    <>
      <Wrapper padding={padding}>
        <div className="row-we-work">
          <div className="col-we-work-2">
            <img src="/sections/Sunpan-Logo_MD-just-sunpan_426U.png" alt=""/>
          </div>
          <div className="col-we-work-2">
            <img src="/sections/pngfind.png" alt=""/>
          </div>
          <div className="col-we-work-2">
            <img src="/sections/LampsPlus_Logo_R.png" alt=""/>
          </div>
          <div className="col-we-work-2">
            <img src="/sections/IHAlogoroundcolor.jpg" alt=""/>
          </div>
          <div className="col-we-work-2">
            <img src="/sections/Universal_Furniture.png" alt=""/>
          </div>
          <div className="col-we-work-2">
            <img src="/sections/ultrasuede_logo.png" alt=""/>
          </div>
          <div className="col-we-work-2">
            <img src="/sections/Piknik_Company_Logo.png" alt=""/>
          </div>
          <div className="col-we-work-2">
          <img src="/sections/TRaditionalHome.png" alt=""/>
          </div>
          <div className="col-we-work-2">
          <img src="/sections/luxehome_blue_grey_WTAG_h80.png" alt=""/>
          </div>
          <div className="col-we-work-2">
          <img src="/sections/ferguson.png" alt=""/>
          </div>
          <div className="col-we-work-2">
          <img src="/sections/TheMart.png" alt=""/>
          </div>
          <div className="col-we-work-2">
          <img src="/sections/eldorado.png" alt=""/>
          </div>
          <div className="col-we-work-2">
          <img src="/sections/DesignCenter.png" alt=""/>
          </div>
          <div className="col-we-work-2">
            <img src="/sections/eflLogo.png" alt=""/>
          </div>
    <div className="col-we-work-2">
            <img src="/sections/logo_home.png" alt=""/>
          </div>
     <div className="col-we-work-2">
            <img src="/sections/logo_Kept.png" alt=""/>
          </div>
    <div className="col-we-work-2">
            <img src="/sections/logo_SR.png" alt=""/>
          </div>
     <div className="col-we-work-2">
            <img src="/sections/logo_frieling.png" alt=""/>
          </div>
      <div className="col-we-work-2">
            <img src="/sections/logo_B+D.png" alt=""/>
          </div>
     <div className="col-we-work-2">
            <img src="/sections/logo_B+D (1).png" alt=""/>
          </div>
     <div className="col-we-work-2">
            <img src="/sections/logo_B+D (2).png" alt=""/>
          </div>
       <div className="col-we-work-2">
            <img src="/sections/logo_B+D (3).png" alt=""/>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default WeWorkWithSection

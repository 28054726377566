import React from 'react'
import styled from 'styled-components'
import { isUnevenNumber } from '../utils/numbers'
import HomeFeatureItem from './HomeFeatureItem'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: ${props => (props.margin ? props.margin : '9em 0 0 0')};
  align-items: center;

  .button-content {
    width: fit-content;

    & a {
      text-decoration: none;
    }
  }

  @media screen and (max-width: 700px) {
    margin: 1em 0 3em 0;
  }
`

const HomeSection = props => {
  const buttonsOne = [
    { to: '/brands', text: 'FOR BRANDS' }, 
    { to: '/influencers', text: 'FOR INFLUENCERS', rounded: true }
  ]

  const buttonsTwo = [
    { to: "https://join.embello.com/digital-services/", target: "_blank", text: 'LEARN MORE' }
  ]

  const features = [
    { 
      title: 'INFLUENCER MARKETING', 
      description: 'Embello helps you access the right influencers and launch tailored campaigns to amplify your digital marketing ' +    
      'activities.',
      buttons: buttonsOne,
      image: '/images/Influencer-Marketing.jpg'
    },
    { 
      title: 'DIGITAL SERVICES', 
      description: 'Let us help you build a comprehensive digital marketing plan that establishes your online brand presence against ' +
      'the competition and converts website traffic into sales.',
      buttons: buttonsTwo,
      image: '/images/Digital-Services-Feature.png'
    }
  ]

  return (
    <Wrapper margin={props.margin}>
      {features.map((feature, index) => (
        <HomeFeatureItem
          key={`homrefeature-${index}`}
          contentPosition={isUnevenNumber(index) ? 'left' : 'right'}
          image={feature.image}
          title={feature.title}
          description={feature.description}
          includeButton
          buttons={feature.buttons}
        
          maxWidth={'1440px'}
        />
      ))}
    </Wrapper>
  )
}

export default HomeSection

import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

const Wrapper = styled.li`
  display: flex;
  flex-direction: row; 
  justify-content: space-between;
  align-items: flex-start;

  .testimonial-quote {
    display: flex;
    flex-direction: row;
    & img {
      width: 100%;
      height: auto;
      width: 77px;
      margin-right: 30px;
    }
  }
  .testimonial-content {
    // width: 77%;
  }
  &.right-testimonial, &.left-testimonial {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
  }
  &.left-testimonial {
    padding-right: 3em;
  }
  &.right-testimonial {
    padding-left: 3em;
  }

  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    flex-basis: 100%;
    margin-bottom: 3em;
    &.left-testimonial {
      padding-right: 0;
      flex-basis: 100%;
    }
    &.right-testimonial {
      padding-left: 0;
      flex-basis: 100%;
    }
  }
  @media screen and (max-width: ${props => props.theme.breakpoint.medium}px) {
    .testimonial-quote img {
      width: 50px;
      margin-right: 21px;
    }
  }
  
`

const AuthorSection = styled.div`
  margin-top: 1.8em;
  margin-bottom: 1em;

  .testimonial-name-right {
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .testimonial-name-right .author-name {
    font-family: Cormorant;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: ${props => props.theme.colors.black};
  }

  .author-image-content {
    min-width: 50px;
    max-height: 51px;
    border-radius: 25.5px;
    overflow: hidden;
    margin-right: 1.3em;
  }
`

const AuthorImage = styled(Img)`
    min-height: 51px;
`

const BrandLogo = styled(Img)`
  margin-top: 0.5em;
  height: ${props => props.height};
  img {
    height: 100% !important;
    width: auto !important;
  }
`

const Headline = styled.h3`
  font-family: Raleway;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 26px;
  letter-spacing: 0.3px;
  color: ${props => props.theme.colors.black};
  text-align: left;
  margin-top: 1.2em;

  @media screen and (max-width: ${props => props.theme.responsive.medium}) {
    font-size: 18px;
    line-height: 22px;
  }
`

const TestimonialCard = ({ 
  slug,
  name,
  heroImage,
  brandLogo,
  body,
  index,
  brandLogoHeight,
  ...props }) => {

  // if index is uneven number card is right and add separation to left side
  let cardClass = '';
  if (index !== undefined) cardClass = (index%2) ? "right-testimonial" : "left-testimonial";

  return (
    <Wrapper featured={props.featured} className={cardClass}>
      <div className="testimonial-quote">
        <img src="/icons/Icon_Messages_Gold.png" alt=""/>
      </div>
      <div className='testimonial-content'>
        <Headline
          dangerouslySetInnerHTML={{ __html: body.childMarkdownRemark.html }}
        />
        <AuthorSection>
          <div className="testimonial-name-right">
            <div className="author-image-content">
              <AuthorImage fluid={heroImage.fluid} />
            </div>
            <div className="author-name">
              {name}
              <BrandLogo height={brandLogoHeight || '22px'} fluid={brandLogo.fluid}/>
            </div>
          </div>
        </AuthorSection>
      </div>
    </Wrapper>
  )
}

export default TestimonialCard
